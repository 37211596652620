export const TAG_REQUEST = 'TAG_REQUEST';
export const TAG_FAIL = 'TAG_FAIL';
export const TAG_RESET = 'TAG_RESET';

export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';

export const LINK_TAG_SUCCESS = 'LINK_TAG_SUCCESS';
export const UNLINK_TAG_SUCCESS = 'UNLINK_TAG_SUCCESS';
