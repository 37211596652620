export const LINK_REQUEST = 'LINK_REQUEST';
export const LINK_FAIL = 'LINK_FAIL';
export const LINK_RESET = 'LINK_RESET';

export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_SUCCESS1 = 'GET_LINK_SUCCESS1';
export const UPDATE_LINK_SUCCESS = 'UPDATE_LINK_SUCCESS';
export const UPDATE_SHARED_LINK_SUCCESS = 'UPDATE_SHARED_LINK_SUCCESS';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
